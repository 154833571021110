<template>
  <div class="reports_section_summary" :class="isFullScreenMode?'reports_section_full_screen':''">
    <div style="width: 100%">
      <b-input v-model="query_filter" placeholder="Type to search"></b-input>
    </div>
    <b-table bordered small selectable select-mode="single" :fields="columns" :items="users" @row-clicked="rowClicked"
             :filter="query_filter" :filter-included-fields="['username']">
      <template #cell(username)="{item}">
        <div class="d-flex">
          <div class="">
            <span class="pb-0 mb-0 reports_dot"></span>
            <span class="pb-0 mb-0 text-left font-weight-bolder"
                  v-tippy="{ placement : 'top',  arrow: true }"
                  :content="item.username +'<br>'+ item.email + '<br>' + item.alpha3_code ">
              {{ (item.username) ? item.username.toUpperCase().substring(0, 15) : '' }}</span>
          </div>
        </div>
      </template>
      <template #cell(targets.credits_to_buy)="{item}">
        <span v-if="item.targets" v-tippy="{ placement : 'top',  arrow: true }"
              :content="item.targets.description">{{ item.targets.credits_to_buy|euro }}  <i class="material-icons">info</i></span>
        <span v-else>{{ 0|number }}</span>
      </template>
      <template #cell(target_status)="{item}">
        <span class="font-weight-bold" :style="{color: item.target_color}">{{ item.target_status }}%</span>
      </template>
      <template #cell(feedback_average)="{item}">
        <span
          :class="item.feedback_average > 0 ?'text-success font-weight-bold':'' "> {{ item.feedback_average|number }} / {{ item.feedback_total|number }}</span>
      </template>
      <template #cell(last_appointment)="{item}">
        <span v-if="item.last_appointment"
              v-tippy="{ placement : 'top',  arrow: true }"
              :content="item.last_appointment.description"
        >{{ item.last_appointment.date }}</span>
      </template>
    </b-table>
  </div>
</template>

<script>
import fullscreen from "@/utils/fullscreen";
import {mapState} from "vuex";

export default {
  name: "dashboard_left",
  mixins: [fullscreen],
  computed: {
    ...mapState("reportUserDashboardStore", ["users"])
  },
  mounted() {
    this.$store.dispatch("reportUserDashboardStore/fetchLogs")
  },
  data: () => ({
    columns: [
      {key: 'username', label: 'Username', tdClass: 'text-right reports_pointer', sortable: true},
      {
        key: 'targets.credits_to_buy',
        label: 'Sales Target Buy',
        tdClass: 'text-right reports_pointer',
        thClass: 'text-center',
        sortable: true
      },
      {key: 'target_status', label: 'Target Status', tdClass: 'text-right reports_pointer', thClass: 'text-center',  sortable: true},
      {
        key: 'feedback_average',
        label: 'Customer rating',
        tdClass: 'text-right reports_pointer',
        thClass: 'text-center',
        sortable: true
      },
      {
        key: 'last_appointment',
        label: 'Scheduled follow-up',
        tdClass: 'text-center reports_pointer',
        thClass: 'text-center',
        sortable: true
      },
    ],
    query_filter: null
  }),
  methods: {
    rowClicked(event) {
      this.$store.commit("reportUserDashboardStore/setUserSelected", event)
    }
  }
}
</script>

<style scoped>

</style>
