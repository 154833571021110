var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reports_section_summary",class:_vm.isFullScreenMode?'reports_section_full_screen':''},[_c('div',{staticStyle:{"width":"100%"}},[_c('b-input',{attrs:{"placeholder":"Type to search"},model:{value:(_vm.query_filter),callback:function ($$v) {_vm.query_filter=$$v},expression:"query_filter"}})],1),_c('b-table',{attrs:{"bordered":"","small":"","selectable":"","select-mode":"single","fields":_vm.columns,"items":_vm.users,"filter":_vm.query_filter,"filter-included-fields":['username']},on:{"row-clicked":_vm.rowClicked},scopedSlots:_vm._u([{key:"cell(username)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('div',{},[_c('span',{staticClass:"pb-0 mb-0 reports_dot"}),_c('span',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement : 'top',  arrow: true }),expression:"{ placement : 'top',  arrow: true }"}],staticClass:"pb-0 mb-0 text-left font-weight-bolder",attrs:{"content":item.username +'<br>'+ item.email + '<br>' + item.alpha3_code}},[_vm._v(" "+_vm._s((item.username) ? item.username.toUpperCase().substring(0, 15) : ''))])])])]}},{key:"cell(targets.credits_to_buy)",fn:function(ref){
var item = ref.item;
return [(item.targets)?_c('span',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement : 'top',  arrow: true }),expression:"{ placement : 'top',  arrow: true }"}],attrs:{"content":item.targets.description}},[_vm._v(_vm._s(_vm._f("euro")(item.targets.credits_to_buy))+" "),_c('i',{staticClass:"material-icons"},[_vm._v("info")])]):_c('span',[_vm._v(_vm._s(_vm._f("number")(0)))])]}},{key:"cell(target_status)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold",style:({color: item.target_color})},[_vm._v(_vm._s(item.target_status)+"%")])]}},{key:"cell(feedback_average)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.feedback_average > 0 ?'text-success font-weight-bold':''},[_vm._v(" "+_vm._s(_vm._f("number")(item.feedback_average))+" / "+_vm._s(_vm._f("number")(item.feedback_total)))])]}},{key:"cell(last_appointment)",fn:function(ref){
var item = ref.item;
return [(item.last_appointment)?_c('span',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement : 'top',  arrow: true }),expression:"{ placement : 'top',  arrow: true }"}],attrs:{"content":item.last_appointment.description}},[_vm._v(_vm._s(item.last_appointment.date))]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }