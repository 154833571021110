<template>
  <div class="reports_section_summary" :class="isFullScreenMode?'reports_section_full_screen':''">
    <div class="row">
      <div class="col-lg-8 col-sm-12">
        <b-input v-model="query_filter" placeholder="Type to search"></b-input>
      </div>
      <div class="col-lg-4 col-sm-12 text-right">
        <b-button variant="primary" @click="showModalPromotion">NEW PROMOTION</b-button>
      </div>
    </div>
    <b-table bordered small selectable select-mode="single" :items="getUsers"
             :fields="columns"
             @row-clicked="rowClicked"
             :filter="query_filter" :filter-included-fields="['username','email']">
      <template #cell(username)="{item}">
        <div class="d-flex">
          <div class="">
            <b-form-checkbox v-model="selected" :value="item.id"></b-form-checkbox>
          </div>
          <div class="">
            <span class="pb-0 mb-0 text-left font-weight-bolder"
                  v-tippy="{ placement : 'top',  arrow: true }"
                  :content="item.username +'<br>'+ item.email + '<br>' + item.alpha3_code ">
              {{ (item.username) ? item.username.toUpperCase().substring(0, 15) : '' }}</span>
          </div>
        </div>
      </template>
      <template #cell(country)="{item}">
        <div class="">
            <span class="pb-0 mb-0 text-left">
              {{ item.alpha3_code }}
            </span>
        </div>
      </template>
    </b-table>
    <b-modal id="modal-create-promotion" title="Create promotion. 10% discount default" no-close-on-esc no-close-on-backdrop hide-footer>
      <b-row class="mb-2">
        <b-col>
          <input v-model="coupon.title" type="text" class="form-control" placeholder="Name promotion">
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col>
          <input v-model="coupon.code" type="text" class="form-control" placeholder="Enter code">
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col>
          <input v-model="coupon.credits" type="number" class="form-control" placeholder="Enter credits amount. Total price order software">
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col>
          <b-form-checkbox
            id="checkbox-shared-subdealer"
            v-model="coupon.shared"
            name="checkbox-shared-subdealer">
            Share this promotion with related dealers (The amount is fixed to everyone)
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <div class="w-100">
          <label>Date start</label>
          <b-form-datepicker id="from_current"
                        placeholder="YYYY-MM-DD"
                        format="yyyy-MM-dd"
                        v-model="coupon.date_start"
                        locale="en" :min="today" typeable calendar-width="350"
          ></b-form-datepicker>
        </div>
        <div class="w-100">
          <label>Date end</label>
          <b-form-datepicker id="to_current"
                        placeholder="YYYY-MM-DD"
                        format="yyyy-MM-dd"
                        v-model="coupon.date_end"
                        locale="en" :min="today" typeable calendar-width="350"
          ></b-form-datepicker>
        </div>
      </b-row>
      <b-row>
        <b-col>
          <b-button :disabled="control.disable_btn_create_coupon" class="btn btn-primary btn-block"
                    @click="createPromotion">SAVE
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import fullscreen from "@/utils/fullscreen";
import {mapGetters, mapMutations} from "vuex";
import * as moment from "moment";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: "promotion_right",
  mixins: [fullscreen, show_alert_mixin],
  computed: {
    ...mapGetters("reportUserPromotionStore", ["getUsers"])
  },
  data: () => ({
    today: null,
    query_filter: null,
    control: {
      disable_btn_create_coupon: false
    },
    selected: [],
    coupon: {
      title: null,
      code: null,
      date_start: null,
      date_end: null,
      credits: null,
      percentage: 100,
      shared: false,
      children: false
    },
    columns: [
      {key: "username", label: 'Username', sortable: true},
      {key: "email", label: 'Email', sortable: true},
      {key: "country", label: 'Country', sortable: true},
      {key: "phone", label: 'Phone', sortable: true},
    ],
  }),
  mounted() {
    this.$store.dispatch("reportUserPromotionStore/fetchUsers")
    this.today = moment().format('Y-MM-DD')
  },
  methods: {
    ...mapMutations("reportUserPromotionStore", ["setUsersSelected"]),
    showModalPromotion() {
      if (this.selected.length) {
        this.$bvModal.show('modal-create-promotion')
      } else {
        this.showAlertNotification("Please select a dealer", "error")
      }
    },
    createPromotion() {
      this.control.disable_btn_create_coupon = true
      if (!this.validateObject()) {
        this.$store.dispatch("reportUserPromotionStore/fetchCreatePromotion", {
          title: this.coupon.title,
          code: this.coupon.code.toUpperCase().replace(/ /g, ""),
          date_start: this.coupon.date_start,
          date_end: this.coupon.date_end,
          credits: {
            limit: this.coupon.credits,
            used: 0
          },
          limit: null,
          description: null,
          users: this.selected.map(x => ({id: x, share: this.coupon.shared})),
          on_modified_price: {
            tvs_retail: false,
            tvs_local: false,
          },
          sub_coupons: [],
        }).then((data) => {
          this.control.disable_btn_create_coupon = false
          if (data == 200) {
            this.$store.commit("reportUserPromotionStore/setDealSelected", null)
            this.$store.commit("reportUserPromotionStore/setUsersSelected", [])
            this.clearData()
            this.$bvModal.hide("modal-create-promotion")
            this.showAlertNotification("Process successful")
          } else this.showAlertNotification("Title or code must be unique", "error")
        })
      } else {
        this.control.disable_btn_create_coupon = false
        this.showAlertNotification("Promotion information is required or current date ranges are incorrect.", "error")
      }
    },
    clearData() {
      this.coupon = {
        title: null,
        code: null,
        date_start: null,
        date_end: null,
        credits: null,
        percentage: 100,
      }
      this.query_filter = null
      this.selected = []
    },
    validateObject() {
      const results = [];
      Object.keys(this.coupon).forEach(el => {
        (this.coupon[el] == null || this.coupon[el] === "") ? results.push(true) : results.push(false)
      })
      results.push(moment(moment(this.coupon.date_end).format("Y-MM-DD")).isBefore(moment(this.coupon.date_start).format("Y-MM-DD")))
      return results.includes(true)
    },
    rowClicked() {

    },
  },
  watch: {
    selected() {
      this.setUsersSelected(this.selected.id)
    }
  }
}
</script>

<style scoped lang="scss">
.b-calendar-inner{
  width: 350px !important;
}
</style>
