<template>
  <div class="reports_section_summary" :class="isFullScreenMode?'reports_section_full_screen':''">
    <history-logs v-if="getUserSelected === null"/>
    <user-detail v-else/>
  </div>
</template>

<script>
import fullscreen from "@/utils/fullscreen";
import {mapGetters} from "vuex";
import historyLogs from "./dashboard_right_logs"
import userDetail from "./dashboard_right_user_detail"

export default {
  name: "dashboard_summary",
  components: {
    historyLogs,
    userDetail
  },
  mixins: [fullscreen],
  computed: {
    ...mapGetters("reportUserDashboardStore", ["getUserSelected"])
  },
}
</script>

<style scoped lang="scss">

</style>
