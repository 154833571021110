<template>
  <b-row class="mx-0">
    <div class="col-lg-4">
      <b-card class="mb-2">
        <b-row>
          <b-col>
            <h6>Dealer information</h6>
          </b-col>
        </b-row>
        <div class="row mb-3">
          <div class="col">
            <span>Username: </span> <br>
            <span>{{ getUserSelected.email }}</span>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            <span>Email: </span> <br>
            <span>{{ getUserSelected.email }}</span>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            <span>Phone: </span> <br>
            <span>{{ getUserSelected.phone }}</span>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            <span>Country: </span> <br>
            <span>{{ getUserSelected.country }}</span>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            <span>Currency: </span> <br>
            <span>{{ getUserSelected.alpha3_code }}</span>
          </div>
        </div>
      </b-card>
      <b-button variant="primary" class="btn-block" v-b-modal.modal-sales-target>Sales target</b-button>
      <b-button variant="primary" class="btn-block" v-b-modal.modal-sales-schedule>Scheduled meet</b-button>
      <b-button variant="primary" class="btn-block" v-b-modal.modal-sales-logs>Custom log</b-button>
      <b-button variant="danger" class="btn-block"
                @click="$store.commit('reportUserDashboardStore/setUserSelected', null)">
        GO BACK
      </b-button>
    </div>
    <div class="col-lg-8">
      <b-card>
        <div class="row">
          <div class="col">
            <div class="row">
              <div class="col">
                <table class="table table-bordered table-sm">
                  <tr>
                    <th colspan="2">Sales Target</th>
                    <th colspan="2" class="text-right">
                      <span v-if="getUserSelected.targets">{{ getUserSelected.targets.credits_to_buy |euro }}</span>
                    </th>
                  </tr>
                  <tr v-tippy="{ placement : 'top',  arrow: true }" content="Percentage days of years">
                    <th colspan="4">
                      <b-progress max="100">
                        <b-progress-bar :value="percentage_year" show-progress animated>
                          <span><strong>{{ percentage_year.toFixed(2) }}%</strong></span>
                        </b-progress-bar>
                      </b-progress>
                    </th>
                  </tr>
                  <tr v-tippy="{ placement : 'top',  arrow: true }" content="Percentage sales target in the year">
                    <th colspan="4">
                      <b-progress max="100" :variant="class_percentage">
                        <b-progress-bar :value="getUserSelected.target_status" show-progress animated>
                          <span><strong>{{ getUserSelected.target_status }}%</strong></span>
                        </b-progress-bar>
                      </b-progress>
                    </th>
                  </tr>
                  <tr>
                    <th colspan="4">Credits</th>
                  </tr>
                  <tr>
                    <th>Bought</th>
                    <th>Price</th>
                    <th>Used</th>
                    <th>Remaining</th>
                  </tr>
                  <tr v-if="getUserSelected.balance">
                    <td>{{ getUserSelected.balance.bought|euro }}</td>
                    <td>{{ getUserSelected.balance.price|euro }}</td>
                    <td>{{ getUserSelected.balance.used|euro }}</td>
                    <td>{{ (getUserSelected.balance.bought + getUserSelected.balance.used)|euro }}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <table class="table table-bordered table-sm">
                  <tr>
                    <th colspan="3">Customer rating</th>
                    <th class="text-right" colspan="2">Average: {{ getUserSelected.feedback_average|number }}</th>
                  </tr>
                  <tr>
                    <th>5 star</th>
                    <th>4 star</th>
                    <th>3 star</th>
                    <th>2 star</th>
                    <th>1 star</th>
                  </tr>
                  <tr>
                    <td>{{ getUserSelected.feedback_ranking[5] }}</td>
                    <td>{{ getUserSelected.feedback_ranking[4] }}</td>
                    <td>{{ getUserSelected.feedback_ranking[3] }}</td>
                    <td>{{ getUserSelected.feedback_ranking[2] }}</td>
                    <td>{{ getUserSelected.feedback_ranking[1] }}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="row custom_height">
              <div class="col table-responsive">
                <table class="table table-bordered table-sm">
                  <tr>
                    <th colspan="3">Scheduled follow-up</th>
                  </tr>
                  <tr>
                    <th>Description</th>
                    <th>Date</th>
                    <th></th>
                  </tr>
                  <tr v-for="(item, index) in getUserSelected.appointments" :key="index">
                    <td>{{ item.description }}</td>
                    <td>{{ item.date_meet|localeDate }}</td>
                    <td><i class="fa fa-trash text-danger" aria-hidden="true"
                           @click="deleteMeet(item._id)"></i></td>
                  </tr>
                </table>
              </div>
            </div>
            <hr>
            <div class="row custom_height">
              <div class="col table-responsive">
                <table class="table table-bordered table-sm">
                  <tr>
                    <th colspan="2">History log</th>
                  </tr>
                  <tr>
                    <th>Description</th>
                    <th>Date</th>
                  </tr>
                  <tr v-for="(item, index) in getUserSelected.logs" :key="index">
                    <td>{{ item.title }}. {{ item.description }}</td>
                    <td>{{ item.created_at|localDateShort }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </b-card>
    </div>
    <b-modal id="modal-sales-target" title="Sales target" hide-footer no-close-on-backdrop no-close-on-esc>
      <b-row>
        <b-col>
          <label for="">Credits target</label>
          <input type="number" v-model="target.credits_to_buy" class="form-control mb-2">
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label for="">Description</label>
          <textarea type="text" v-model="target.description" class="form-control mb-2"></textarea>
          <b-button class="btn-block btn-primary" @click="createSalesTarget">SAVE</b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal id="modal-sales-logs" title="Custom Logs" hide-footer no-close-on-backdrop no-close-on-esc>
      <b-row>
        <b-col>
          <input type="text" class="form-control mb-2" v-model="custom_logs.title" placeholder="Title">
          <textarea type="text" rows="4" v-model="custom_logs.description" class="form-control mb-2"
                    placeholder="Description"></textarea>
          <b-button class="btn-block btn-primary" @click="createCustomLog">SAVE</b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal id="modal-sales-schedule" title="Scheduled meet" hide-footer no-close-on-backdrop no-close-on-esc>
      <b-row>
        <b-col>
           <textarea v-model="meet.description" type="text" class="form-control" rows="5"
                     placeholder="Description"></textarea>
          <label for="" class="mt-2">Select day</label>
          <b-datepicker id="from_current"
                        placeholder="YYYY-MM-DD"
                        format="yyyy-MM-dd"
                        v-model="meet.calendar"
                        locale="en"
                        typeable
          ></b-datepicker>
          <label for="" class="mt-2">Select hour</label>
          <b-form-timepicker class="btn-block clock"
                             value="text"
                             placeholder="text"
                             v-model="meet.time_picker" locale="en" hide-header
                             required button-only button-variant="white"
                             no-close-button>
          </b-form-timepicker>
          <b-button class="btn-block btn-primary mt-2" @click="createMeet">SAVE</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </b-row>
</template>

<script>
import {mapGetters} from "vuex";
import * as moment from 'moment'
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: "dashboard_right_logs",
  mixins: [show_alert_mixin],
  data: () => ({
    percentage_year: (moment().dayOfYear() * 100) / 365,
    meet: {
      description: null,
      calendar: null,
      time_picker: null
    },
    target: {
      time_expected: 12,
      credits_to_buy: 0,
      credits_to_use: 0,
      description: null
    },
    custom_logs: {
      title: null,
      description: null
    }
  }),
  computed: {
    ...mapGetters("reportUserDashboardStore", ["getUserSelected"]),
    class_percentage: function () {
      const _number = (this.getUserSelected.target_status * 100) / this.percentage_year;
      return _number >= 100 ? "success" : _number < 70 ? "danger" : "warning"
    }
  },
  methods: {
    createSalesTarget() {
      if (this.target.credits_to_buy >= 1 && !!this.getUserSelected)
        this.$store.dispatch("reportUserDashboardStore/fetchCreateSalesTarget", {
          ...this.target,
          username: this.getUserSelected.username,
          id: this.getUserSelected.id
        }).then(() => {
          this.clearData()
          this.$store.dispatch("reportUserDashboardStore/fetchUsers")
          this.$bvModal.hide("modal-sales-target")
          this.showAlertNotification('Process successful')
        })
    },

    createMeet() {
      if (this.meet.description.length && this.meet.description.length <= 2500 && this.meet.time_picker) {
        const temp = moment(this.meet.calendar).format("YYYY-MM-DD")
        const full_date = temp + " " + this.meet.time_picker
        this.$store.dispatch("reportUserDashboardStore/fetchCreateMeet", {
          date_meet: full_date,
          description: this.meet.description,
          username: this.getUserSelected.username,
          id: this.getUserSelected.id,
        }).then(() => {
          this.clearData()
          this.$store.dispatch("reportUserDashboardStore/fetchUsers")
          this.$bvModal.hide("modal-sales-schedule")
          this.showAlertNotification("Process successful")
        })
      }
    },

    deleteMeet(id) {
      this.$store.dispatch('reportUserDashboardStore/fetchDeleteMeet', {id: id}).then(() => {
        this.$store.dispatch("reportUserDashboardStore/fetchUsers")
        this.showAlertNotification("Process successful")
      })
    },

    createCustomLog() {
      if (this.custom_logs.title && this.custom_logs.title.length <= 250 && this.custom_logs.description && this.custom_logs.description.length <= 2500)
        this.$store.dispatch('reportUserDashboardStore/fetchCreateCustomLog', {
          id: this.getUserSelected.id,
          title: this.custom_logs.title,
          description: this.custom_logs.description
        }).then(() => {
          this.$store.dispatch("reportUserDashboardStore/fetchLogs").then(() => {
            this.$store.dispatch("reportUserDashboardStore/fetchUsers")
            this.clearData()
            this.$bvModal.hide("modal-sales-logs")
            this.showAlertNotification("Process successful")
          })
        })
    },

    clearData() {
      this.target = {
        time_expected: 1,
        credits_to_buy: 0,
        credits_to_use: 0
      }
      this.meet = {
        description: null,
        calendar: null,
        time_picker: null
      }
      this.custom_logs = {
        title: null,
        description: null,
      }
    },
  }
}
</script>

<style scoped lang="scss">
.custom_height {
  max-height: 10rem;
  overflow-y: scroll;
}
</style>
