<template>
  <div class="reports_section_summary" :class="isFullScreenMode?'reports_section_full_screen':''">
    <div class="w-100 text-left">
      <h6>Deals list</h6>
    </div>
    <b-table bordered small selectable select-mode="single" :fields="columns" :items="getDeals"
             @row-clicked="rowClicked"
             :filter="query_filter" :filter-included-fields="['coupon.code','title']">
      <template #cell(title)="{item}">
        <span>{{ item.title.substring(0, 90) }}</span>
        <span v-if="item.title.length > 90">...</span>
      </template>
      <template #cell(coupon.credits.limit)="{item}">
        <span v-if="item.coupon">{{ item.coupon.credits.limit|euro }}</span>
      </template>
      <template #cell(coupon.credits.used)="{item}">
        <span v-if="item.coupon">{{ item.coupon.credits.used|euro }}</span>
      </template>
      <template #cell(coupon.date_start)="{item}">
        <span v-if="item.coupon">{{ item.coupon.date_start|localDateShort }}</span>
      </template>
      <template #cell(coupon.date_end)="{item}">
        <span v-if="item.coupon">{{ item.coupon.date_end|localDateShort }}</span>
      </template>
    </b-table>
  </div>
</template>

<script>
import fullscreen from "@/utils/fullscreen";
import {mapGetters} from "vuex";

export default {
  name: "promotion_left",
  mixins: [fullscreen],
  components: {},
  data: () => ({
    columns: [
      {
        key: "title",
        label: "Title",
        tdClass: 'reports_pointer custom_size',
        thClass: 'text-center custom_size',
        sortable: true
      },
      {
        key: "users.length",
        label: "Users",
        tdClass: 'reports_pointer custom_size',
        thClass: 'text-center custom_size',
        sortable: true
      },
      {key: "coupon.code", label: "Code", tdClass: 'reports_pointer', thClass: 'text-center', sortable: true},
      {
        key: "coupon.credits.limit",
        label: "Credits limit",
        tdClass: 'text-right reports_pointer',
        thClass: 'text-center',
        sortable: true
      },
      {
        key: "coupon.credits.used",
        label: "Credits used",
        tdClass: 'text-right reports_pointer',
        thClass: 'text-center',
        sortable: true
      },
      {
        key: "coupon.date_start",
        label: "Date start",
        tdClass: 'text-center reports_pointer',
        thClass: 'text-center',
        sortable: true
      },
      {
        key: "coupon.date_end",
        label: "Date end",
        tdClass: 'text-center reports_pointer',
        thClass: 'text-center',
        sortable: true
      },
    ],
    query_filter: null,
  }),
  computed: {
    ...mapGetters("reportUserPromotionStore", ["getDeals"])
  },
  methods: {
    rowClicked(event) {
      this.$store.commit("reportUserPromotionStore/setDealSelected", event)
    }
  }
}
</script>

<style scoped lang="scss">
.table tr td > .custom_size {
  width: 100px !important;
  white-space: break-spaces !important;
  word-break: break-all;
}
</style>
