<template>
  <div>
    <b-table bordered small selectable select-mode="single" :items="getLogs" :fields="columns" @row-clicked="rowClicked"
             :filter="query_filter" :filter-included-fields="['title']">
      <template #thead-top="data">
        <b-tr>
          <b-th colspan="2" class="text-center"><span>History Logs</span></b-th>
        </b-tr>
      </template>
      <template #cell(created_at)="{item}">
        <span>{{ item.created_at|localDateShort }}</span>
      </template>
    </b-table>
    <b-pagination
      ref="paginator"
      @change="paginate"
      class="justify-content-end"
      :value="logs_pagination.limit"
      :total-rows="logs_pagination.rows"
      :per-page="logs_pagination.limit"
    ></b-pagination>
    <b-modal id="modal-logs-detail" title="Detail Logs" hide-footer no-close-on-backdrop no-close-on-esc>
      <b-row v-if="log_selected">
        <b-col>
          <h5>{{log_selected.title}}</h5>
          <p>{{log_selected.created_at|localeDate}}</p>
          <hr>
          <p>{{log_selected.description}}</p>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
  name: "dashboard_right_logs",
  computed: {
    ...mapState("reportUserDashboardStore", ["logs_pagination"]),
    ...mapGetters("reportUserDashboardStore", ["getLogs"])
  },
  data: () => ({
    columns: [
      {key: "title", label: "Title", tdClass: 'reports_pointer',  sortable: true},
      {key: "created_at", label: "Date", tdClass: 'text-right reports_pointer', thClass: 'text-right',  sortable: true},
    ],
    query_filter: null,
    log_selected: null
  }),
  methods: {
    async paginate(page) {
      this.$store.commit("reportUserDashboardStore/setPagination", {
        page: page,
        limit: this.logs_pagination.limit,
        rows: this.logs_pagination.rows
      })
      this.$store.dispatch("reportUserDashboardStore/fetchLogs")
    },
    rowClicked(row) {
      this.log_selected = row
      this.$bvModal.show("modal-logs-detail")
    }
  }
}
</script>

<style scoped>

</style>
