<template>
  <div>
    <b-overlay :show="showOverlay" rounded="lg">
      <b-row v-if="params.page === 'dashboard'">
        <div class="col-lg-6 col-md-12 col-sm-12 custom_div" id="section_graphic">
          <dashboard-left/>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 custom_div" id="section_summary">
          <dashboard-right/>
        </div>
      </b-row>

      <b-row v-if="params.page === 'promotion'">
        <div class="col-lg-6 col-md-12 col-sm-12 custom_div" id="section_graphic">
          <promotion-left/>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 custom_div" id="section_summary">
          <promotion-detail v-if="getDealSelected !== null"/>
          <promotion-right v-else/>
        </div>
      </b-row>

      <b-row class="d-flex justify-content-center mt-2">
        <div class="col-lg-2 col-md-4 col-sm-12">
          <select class="form-control" @change="switchPage" v-model="params.page">
            <option value="dashboard">Default dashboard</option>
            <option value="promotion">Promotions deals</option>
          </select>
        </div>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import dashboardLeft from "./dashboard_left"
import dashboardRight from "./dashboard_right"
import promotionLeft from "./promotion_left"
import promotionRight from "./promotion_right"
import promotionDetail from "./promotion_detail"
import {mapGetters} from "vuex";

export default {
  name: "ReportsUser",
  components: {
    dashboardLeft,
    dashboardRight,
    promotionLeft,
    promotionRight,
    promotionDetail,
  },
  data: () => ({
    showOverlay: false,
    params: {
      page: 'dashboard'
    }
  }),
  mounted() {
    this.init()
  },
  computed: {
    ...mapGetters("reportUserPromotionStore", ["getDealSelected"])
  },
  methods: {
    switchPage() {
      this.init()
    },
    init() {
      this.showOverlay = true
      if (this.params.page === 'dashboard') {
        this.$store.dispatch("reportUserDashboardStore/fetchUsers")
        this.$store.dispatch("reportUserDashboardStore/fetchLogs").then(() => this.showOverlay = false)
        this.$store.commit("reportUserDashboardStore/setUserSelected", null)
      } else {
        this.$store.dispatch("reportUserPromotionStore/fetchDeals")
        this.$store.dispatch("reportUserPromotionStore/fetchUsers").then(() => this.showOverlay = false)
        this.$store.commit("reportUserPromotionStore/setDealSelected", null)
        this.$store.commit("reportUserPromotionStore/setUsersSelected", [])
      }
    }
  }
}
</script>

<style scoped>

</style>
