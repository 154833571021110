var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reports_section_summary",class:_vm.isFullScreenMode?'reports_section_full_screen':''},[_vm._m(0),_c('b-table',{attrs:{"bordered":"","small":"","selectable":"","select-mode":"single","fields":_vm.columns,"items":_vm.getDeals,"filter":_vm.query_filter,"filter-included-fields":['coupon.code','title']},on:{"row-clicked":_vm.rowClicked},scopedSlots:_vm._u([{key:"cell(title)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.title.substring(0, 90)))]),(item.title.length > 90)?_c('span',[_vm._v("...")]):_vm._e()]}},{key:"cell(coupon.credits.limit)",fn:function(ref){
var item = ref.item;
return [(item.coupon)?_c('span',[_vm._v(_vm._s(_vm._f("euro")(item.coupon.credits.limit)))]):_vm._e()]}},{key:"cell(coupon.credits.used)",fn:function(ref){
var item = ref.item;
return [(item.coupon)?_c('span',[_vm._v(_vm._s(_vm._f("euro")(item.coupon.credits.used)))]):_vm._e()]}},{key:"cell(coupon.date_start)",fn:function(ref){
var item = ref.item;
return [(item.coupon)?_c('span',[_vm._v(_vm._s(_vm._f("localDateShort")(item.coupon.date_start)))]):_vm._e()]}},{key:"cell(coupon.date_end)",fn:function(ref){
var item = ref.item;
return [(item.coupon)?_c('span',[_vm._v(_vm._s(_vm._f("localDateShort")(item.coupon.date_end)))]):_vm._e()]}}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100 text-left"},[_c('h6',[_vm._v("Deals list")])])}]

export { render, staticRenderFns }