<template>
  <div class="reports_section_summary" :class="isFullScreenMode?'reports_section_full_screen':''">
    <div class="w-100 text-right">
      <b-button variant="primary" class="text-right"
                @click="$store.commit('reportUserPromotionStore/setDealSelected', null)">GO
        BACK
      </b-button>
    </div>
    <table class="table table-bordered table-hover table-sm">
      <thead>
      <tr>
        <th colspan="4">
          Promotion information
        </th>
      </tr>
      <tr>
        <th>Title</th>
        <th>Total users</th>
        <th>Date created</th>
        <th>Status</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>{{ getDealSelected.title }}</td>
        <td>{{ getDealSelected.users.length }}</td>
        <td>{{ getDealSelected.created_at|localDateShort }}</td>
        <td><span :class="Number(getDealSelected.coupon.credits.limit) > Number(getDealSelected.coupon.credits.used)?'text-success':''">{{
            Number(getDealSelected.coupon.credits.limit) > Number(getDealSelected.coupon.credits.used) ? 'ACTIVE' : 'INACTIVE'
          }}</span></td>
      </tr>
      </tbody>
    </table>
    <hr>
    <table class="table table-bordered table-hover table-sm">
      <thead>
      <tr>
        <th colspan="5">
          Coupon information
        </th>
      </tr>
      <tr>
        <th>Code</th>
        <th>Credits limit</th>
        <th>Credits used</th>
        <th>Date Start</th>
        <th>Date End</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>{{ getDealSelected.coupon.code }}</td>
        <td>{{ getDealSelected.coupon.credits.limit|euro }}</td>
        <td>{{ getDealSelected.coupon.credits.used|euro }}</td>
        <td>{{ getDealSelected.coupon.date_start|localDateShort }}</td>
        <td>{{ getDealSelected.coupon.date_end|localDateShort }}</td>
      </tr>
      </tbody>
    </table>
    <hr>
    <table class="table table-bordered table-hover table-sm">
      <thead>
      <tr>
        <th colspan="4">
          Users information
        </th>
      </tr>
      <tr>
        <th>Name</th>
        <th>Email</th>
        <th>Country</th>
        <th>Phone</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for=" (item, index) in getDealSelected.users" :key="index">
        <td>{{ item.username }}</td>
        <td>{{ item.email }}</td>
        <td>{{ item.country.substring(0, 20) }}</td>
        <td>{{ item.phone }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import fullscreen from "@/utils/fullscreen";

export default {
  name: "promotion_detail",
  mixins: [fullscreen],
  computed: {
    ...mapGetters("reportUserPromotionStore", ["getDealSelected"])
  }
}
</script>

<style scoped>

</style>
